<template>
    <div>
        <page-header title="Xceptions" subtitle="Settings">
            <div slot="tools">
                <button class="btn-transparent" @click="$router.go(-1)">Cancel</button>
                <button :disabled="referenceIsSame || errorsExist" class="btn-primary ml-6" @click="save()">Save</button>
            </div>
        </page-header>

        <card v-for="(entity, indexA) in settings" :title="entity.name" class="mb-8" :key="indexA">

            <div class="w-full">
                <div v-for="(module, indexB) in entity.modules" :key="indexB" class="border-b py-4">

                    <div class="flex items-center">
                        <div>
                            <checkbox :checked="module.active" @click="module.active = !module.active" :name="module.name"></checkbox>
                        </div>

                        <div class="ml-4">
                            {{ module.name }}
                        </div>

                        <div class="flex items-center ml-auto">

                            <div v-show="moduleHasError(module)" class="mr-8">
                                <fa :icon="['fal','exclamation-triangle']" fixed-width class="text-xl text-red-600" />
                            </div>

                            <button class="btn-primary" @click="edit(module, entity)">Edit</button>
                        </div>
                    </div>

                </div>
            </div>

        </card>


        <modal :show="showEditModal" @hide="closeEditModal()" size="md">
            <h4 v-if="activeModule" slot="header">{{ activeModule.name }}</h4>

            <div slot="body" v-if="activeModule">
                <div v-if="activeModule.sendTo.length > 0" class="pb-4">
                    <h4>Send to:</h4>
                    <div class="mt-2">
                        <div v-for="(sendTo, index) in activeModule.sendTo" :key="`sendTo_${index}`" class="flex border-b py-3 items-center">
                            <p :for="sendTo.id">{{ sendTo.label }}</p>
                            <div class="flex ml-auto items-center">
                                <div>
                                    <label>Email</label>
                                    <checkbox class="mt-1" :id="`${sendTo.id}-mail`" v-model="sendTo.mail"></checkbox>
                                </div>

                                <div class="ml-4">
                                    <label>SMS</label>
                                    <checkbox class="mt-1" :id="`${sendTo.id}-sms`" v-model="sendTo.sms"></checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="activeModule.parameters.length > 0" class="mt-4 border-b pb-4">
                    <h4>Parameters:</h4>
                    <div v-for="(parameter, index) in activeModule.parameters" :key="`parameter_${index}`" class="mt-2">
                        <div class="flex items-center">
                            <p class="mr-2">{{ parameter.prefix }}</p>
                            <t-input v-model="parameter.value" type="number" :min="1" class="max-w-20"></t-input>
                            <p class="ml-2">{{ parameter.suffix }}</p>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <h4>Exclude carrier services:</h4>
                    <div class="mt-2">
                        <multi-select v-model="activeModule.exclude.carrier_services" :options="carrierServices" :multiple="true" />
                    </div>
                </div>
            </div>

            <div slot="footer" class="flex w-full mt-8">
                <div class="ml-auto">
                    <button @click="closeEditModal()" class="btn-primary">Done</button>
                </div>
            </div>
        </modal>

        <modal :show="confirmLeaveModal" @hide="closeConfirmLeave()" size="sm">
            <h4 slot="header" class="ml-2">Unsaved changes</h4>

            <div slot="body" class="">
                You have made changes that have not been saved. Are you sure you want to leave?
            </div>

            <div slot="footer" class="flex w-full mt-8">
                <div class="ml-auto">
                    <button @click="setConfirmLeave(true)" class="btn-transparent">Leave</button>
                    <button @click="save(true)" class="btn-primary ml-4">Save & Leave</button>
                    <button @click="setConfirmLeave(false)" class="btn-primary ml-4">Stay</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import axios from "axios";
import TInput from "../../components/TInput";
import MultiSelect from "../../components/MultiSelect";

export default {

    name: 'ExceptionSettings',

    components: {MultiSelect, TInput},

    metaInfo () {
        return { title: 'Exception settings' }
    },

    data: () => ({
        errors: {},
        errorsExist: false,
        carrierServices: [],
        activeModule: null,
        activeEntity: null,
        siteSettings: [],
        settings: [],
        startReference: null,
        confirmLeaveModal: false,
        confirmLeave: null,
    }),

    mounted() {
        this.settings = JSON.parse(JSON.stringify(this.settingsTemplate))
        this.fetchSettings();
        this.fetchCarriers();
    },

    beforeRouteLeave (to, from, next) {
        const vm = this;

        if (this.referenceIsSame) {
            next()
            return;
        }

        this.awaitConfirmLeave().then(() => {
            vm.confirmLeave = null;
            next()
        }).catch(() => {
            vm.confirmLeave = null;
            next(false)
        });
    },

    watch: {
        settings: {
            deep: true,

            handler() {
                this.findErrors();
                this.errorsExist = this.hasErrors();
            }
        }
    },

    computed: {

        siteId() {
            const param = this.$route.params.id;
            return param ? param : this.activeSite.id;
        },

        activeSite() {
            return this.$store.getters["auth/site"]
        },

        showEditModal() {
            return !!this.activeModule;
        },

        referenceIsSame() {
            return this.startReference === JSON.stringify(this.settings);
        },

        settingsTemplate() {
            return [
                {
                    id: 'consignments',
                    name: 'Consignments',
                    modules: [
                        {
                            id: 'transport_events',
                            name: 'Transport events',
                            active: false,
                            parameters: [],
                            exclude: {
                                carrier_services: []
                            },
                            sendTo: this.consignmentSendToTemplate
                        },
                        {
                            id: 'no_pickup',
                            name: 'No pickup',
                            active: false,
                            parameters: [
                                {
                                    id: 'hours',
                                    prefix: 'After',
                                    suffix: 'hours',
                                    value: null,
                                }
                            ],
                            exclude: {
                                carrier_services: []
                            },
                            sendTo: this.consignmentSendToTemplate
                        },
                        {
                            id: 'no_scanning',
                            name: 'No scanning',
                            active: false,
                            parameters: [
                                {
                                    id: 'hours',
                                    prefix: 'After',
                                    suffix: 'hours',
                                    value: null,
                                }
                            ],
                            exclude: {
                                carrier_services: []
                            },
                            sendTo: this.consignmentSendToTemplate
                        },
                        {
                            id: 'no_progress',
                            name: 'No progress',
                            active: false,
                            parameters: [],
                            exclude: {
                                carrier_services: []
                            },
                            sendTo: this.consignmentSendToTemplate
                        },
                        {
                            id: 'not_delivered',
                            name: 'Not delivered',
                            active: false,
                            parameters: [
                                {
                                    id: 'hours',
                                    prefix: 'After',
                                    suffix: 'hours of creation',
                                    value: null,
                                }
                            ],
                            exclude: {
                                carrier_services: []
                            },
                            sendTo: this.consignmentSendToTemplate
                        },
                        {
                            id: 'eta_failed',
                            name: 'ETA failed',
                            active: false,
                            parameters: [],
                            exclude: {
                                carrier_services: []
                            },
                            sendTo: this.consignmentSendToTemplate
                        },
                        {
                            id: 'eta_changed',
                            name: 'ETA changed',
                            active: false,
                            parameters: [],
                            exclude: {
                                carrier_services: []
                            },
                            sendTo: this.consignmentSendToTemplate
                        },
                    ]
                },
                // {
                //     id: 'emissions',
                //     name: 'Emissions',
                //     sendTo: [],
                //     modules: [
                //         {
                //             id: 'placeholder',
                //             name: 'Placeholder',
                //             active: false,
                //             parameters: [],
                //             exclude: {
                //                 carrier_services: []
                //             },
                //             sendTo: [],
                //         }
                //     ],
                // }
            ]
        },

        consignmentSendToTemplate() {
            return [
                {
                    id: 'consignor',
                    label: 'Consignor',
                    mail: false,
                    sms: false,
                },
                {
                    id: 'consignee',
                    label: 'Consignee',
                    mail: false,
                    sms: false,
                },
                {
                    id: 'pickup',
                    label: 'Pickup',
                    mail: false,
                    sms: false,
                },
                {
                    id: 'delivery',
                    label: 'Delivery',
                    mail: false,
                    sms: false,
                },
            ]
        },
    },

    methods: {

        hasErrors() {
            let hasErrors = false;

            this.settings.forEach((entity) => {

                entity.modules.forEach((module) => {

                    let moduleHasError = this.moduleHasError(module);

                    if (moduleHasError) {

                        hasErrors = true;
                    }

                })

            })

            return hasErrors
        },

        moduleHasError(module) {
            let hasError = false;

            if (module.active) {

                module.parameters.forEach((parameter) => {

                    if (parameter.error) {
                        hasError = true;
                    }
                })
            }

            return hasError;
        },

        findErrors() {
            this.settings.forEach((entity) => {

                entity.modules.forEach((module) => {

                    if (module.active) {

                        module.parameters.forEach((parameter) => {

                            if (! parameter.value) {
                                parameter.error = 'Value is required'
                            } else {
                                parameter.error = null;
                            }

                        })

                    }
                })
            })
        },

        async awaitConfirmLeave() {
            this.confirmLeaveModal = true;
            const vm = this;

            return await new Promise(function (resolve, reject) {
                (function waitForIt() {
                    if (vm.confirmLeave) return resolve();
                    if (vm.confirmLeave === false) {
                        vm.confirmLeaveModal = false;
                        return reject();
                    }
                    setTimeout(waitForIt, 30);
                })();
            });
        },

        setConfirmLeave(value) {
            this.confirmLeave = value;
        },

        closeConfirmLeave() {
            this.confirmLeaveModal = false;
        },

        async fetchSettings() {
            const { data } = await axios.get(`${this.$apiUrl.settings.site}/${this.siteId}/exceptions`);

            this.siteSettings = data.data;
            this.mapSettings();
            this.startReference = JSON.stringify(this.settings);
        },

        mapSettings() {
            this.settings.forEach((entity) => {

                entity.modules.forEach((module) => {
                    const foundModule = this.findSiteModule(entity.id, module.id)

                    if (foundModule) {
                        module.active = foundModule.active
                        module.exclude = foundModule.exclude
                    }

                    module.parameters.forEach((parameter) => {
                        const foundParameter = this.findSiteParameter(entity.id, module.id, parameter.id)

                        if (foundParameter) {
                            parameter.value = foundParameter.value;
                        }
                    })

                    module.sendTo.forEach((sendTo) => {
                        const foundSendTo = this.findSiteSendTo(entity.id, module.id, sendTo.id)

                        if (foundSendTo) {
                            sendTo.sms = foundSendTo.sms;
                            sendTo.mail = foundSendTo.mail;
                        }
                    })
                })

            })
        },

        findSiteEntity(entityId) {
            return this.siteSettings.find((entity) => {
                return entity.id === entityId;
            })
        },

        findSiteModule(entityId, moduleId) {
            const entity = this.findSiteEntity(entityId)

            return entity ? entity.modules.find((module) => {
                return module.id === moduleId;
            }) : false;
        },

        findSiteParameter(entityId, moduleId, parameterId) {
            const module = this.findSiteModule(entityId, moduleId)

            return module ? module.parameters.find((parameter) => {
                return parameter.id === parameterId;
            }) : false;
        },

        findSiteSendTo(entityId, moduleId, sendToId) {
            const module = this.findSiteModule(entityId, moduleId)

            return module ? module.sendTo.find((sendTo) => {
                return sendTo.id === sendToId;
            }) : false;
        },

        save(leaving = false) {
            axios.patch(`${this.$apiUrl.settings.site}/${this.siteId}/exceptions`, {data: this.settings}).then(() => {
                this.$snotify.success('Settings saved');

                if (leaving) {
                    this.confirmLeave = true;
                }

                this.startReference = JSON.stringify(this.settings);

            }).catch((error) => {
                this.errors = error.response.data.errors;
            })
        },

        closeEditModal() {
            this.activeModule = null;
            this.activeEntity = null;
        },

        edit(module, entity) {
            this.activeModule = module;
            this.activeEntity = entity;
        },

        async fetchCarriers() {
            const { data } = await axios.get(this.$apiUrl.filters + '?options[]=carrier_service_from_source&owner=0');

            this.carrierServices = data.data.carrier_service_from_source.map((carrierService) => {
                return carrierService;
            });
        }
    }
}
</script>
